<template>
 <validation-observer v-slot="{ handleSubmit }">
    <b-field v-if="!extracted">
    <validation-provider
            v-slot="{ errors, valid }"
            :rules= {excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
            :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
            does_not_contains:'The dataset name should not contain special symbols (except underscore) nor startwith numbers.',
            authorize_name: 'This dataset name is not allowed.',not_too_long: 'This dataset name is too long'}"
            name="Dataset name"
          >
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
        >
          <b-input
            v-model="newDatasetName"
            expanded
            icon="tag"
            placeholder="Dataset name"
          ></b-input>
        </b-field>
      </validation-provider>
      <p class="control">
        <b-button rounded
          class="is-primary button-shadow"
          @click="handleSubmit(runExtract)"
          >
          <span class="icon is-small"><i class="fa fa-pencil-square-o"></i></span>
          <span> Create a new dataset</span>
        </b-button>
      </p>
      </b-field>
      <b-notification
        v-if="extracted && extractedSuccess"
        type="is-success"
        aria-close-label="Close notification"
        role="alert"
      >
      Dataset extracted successfully.
      </b-notification>
      <b-notification
        v-if="extracted && !extractedSuccess"
        type="is-danger"
        aria-close-label="Close notification"
        role="alert"
      >
      Error while extracting dataset.
      </b-notification>

  </validation-observer>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'RExtractDataset',
  props: {
    datasetName: {
      type: String,
      default: ''
    },
    baseName: {
      type: String,
      default: 'extr'
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  data () {
    return {
      newDatasetName: this.baseName + '_' + this.datasetName,
      extracted: false,
      extractedSuccess: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    datasetsNames: function () {
      return this.workspaceStore.datasets.map(dataset => dataset.user_name)
    }
  },
  mounted () {
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      }, (err) => {
        return {
          valid: false,
          data: { message: 'This dataset name is forbidden' + err }
        }
      })
    })
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
  },
  methods: {
    runExtract: function () {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      let params = {
        'func_name': 'r_extract_dataset',
        'datasetName': this.datasetName,
        'userName': this.newDatasetName,
        'out_graph': true
      }
      this.workspaceStore.runRFunction(params)
        .then(data => {
          loadingComponent.close()
          this.extracted = true
          this.extractedSuccess = true
        })
        .catch(() => {
          loadingComponent.close()
          this.extracted = true
          this.extractedSuccess = false
        })
    }
  }
}
</script>
<style scoped>
ul {
  list-style: inside;
  padding-left: 10px;
}
</style>
